<template>



    <div class="card" >



      <div class="card-body ">

        <b-row>

                  <b-col cols="1" sm="1" md="1" lg="1" xl="1">

                    
                      <b-skeleton width="10px" v-if="loading" height="10px" type="button"></b-skeleton>

                    

                    <feather-icon
                     v-else
                    icon="RefreshCcwIcon"
                    size="18"
                    @click="refreshEQCoins"
                    class="reload"
                    />


             </b-col>

    

              <b-col cols="10" sm="10" md="10" lg="10" xl="10" >



            </b-col>

            <b-col cols="1" sm="1" md="1" lg="1" xl="1"></b-col>

     

            <b-col cols="8" sm="8" md="8" lg="8" xl="8" >
            

                <p style=" text-align: left;
                        font-size: 12px;
                        margin-top: 8px;
                    margin-bottom:0px; ">EQCoins ganados
                    <feather-icon
                        icon="HelpCircleIcon"
                        size="16"
                        v-b-popover.hover.bottom.html="'Cantidad de EQCoins ganados por usuarios registrados y verificados con tu link de promotor'"
                        title="EQCoins por referir"
                        style="margin-right: 5px;cursor:pointer;"
                        /> </p>



                        <div v-if="loading">
                          <b-skeleton width="100%" type="button" style="margin-bottom: 10px"></b-skeleton>

                        </div>

                  <p  v-else style=" text-align: left; font-size: 30px; margin-top: 5px; margin-bottom: 20px; font-weight: bold">{{EQCoins}}<small style=" font-weight: bold;
                    position: absolute;
                    top: 27px;
                    font-size: 10px;">EQCoins</small></p>
                

            </b-col>

             <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding: 0;display:flex" >

         
                <svg style="position: absolute;right: 0;top: -25px;margin:auto; fill:#6a6a6a; width: 60px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512"><g><path d="m176 224c-61.758.0-112 50.242-112 112s50.242 112 112 112 112-50.242 112-112-50.242-112-112-112zm0 192c-44.109.0-80-35.891-80-80s35.891-80 80-80 80 35.891 80 80-35.891 80-80 80z"/><path d="m192 352v-48c0-8.836-7.164-16-16-16h-16c-8.836.0-16 7.164-16 16s7.164 16 16 16v32c-8.836.0-16 7.164-16 16s7.164 16 16 16h32c8.836.0 16-7.164 16-16s-7.164-16-16-16z"/><path d="M319.867.0C224.523.0 128 27.477 128 80v86.823C54.238 187.788.0 255.609.0 336c0 97.047 78.953 176 176 176 38.672.0 74.352-12.685 103.426-33.908C292.816 479.226 306.359 480 320 480c95.414.0 192-27.477 192-80V80c0-52.523-96.656-80-192.133-80zM32 336c0-79.398 64.602-144 144-144s144 64.602 144 144-64.602 144-144 144S32 415.398 32 336zm448 64c0 19.109-63.813 48-160 48-2.818.0-5.371-.215-8.121-.271 11.822-14.352 21.262-30.675 28.102-48.289 54.863-2.231 107.734-13.47 140.02-33.425V4e2zm0-80c0 17.095-51.244 41.965-130.906 47.021C350.898 356.932 352 346.6 352 336c0-5.804-.32-11.534-.871-17.195 50.795-3.225 98.785-14.192 128.871-32.789V320zm0-80c0 17.386-53.023 42.789-135.02 47.236-4.902-16.961-12.25-32.869-21.707-47.316 60.66-.418 121.17-11.927 156.727-33.904V240zm0-80c0 19.109-63.945 48-160.133 48-8.367.0-16.383-.296-24.227-.713C264.203 178.047 222.219 160 176 160c-5.344.0-10.607.333-15.832.802C160.145 160.539 160 160.26 160 160v-33.887C196.221 148.439 258.207 160 319.867 160c61.772.0 123.869-11.57 160.133-33.914V160zM319.867 128C223.758 128 160 99.109 160 80s63.758-48 159.867-48C416.133 32 480 60.891 480 80s-63.867 48-160.133 48z"/></g></svg>
            


             </b-col>



        </b-row>


      </div>

   

    </div>



</template>

<script>
import {
BButton,BCardFooter,BLink,BFormSelect,VBTooltip, VBPopover, BRow, BCol, BCard,BSkeleton, BAvatar
} from 'bootstrap-vue'


export default {
components: {
BButton,
BCardFooter,
BRow,
BCol,
BLink,
BCard,
VBPopover,
BAvatar,
BFormSelect,
BSkeleton,
},
directives: {
'b-popover': VBPopover,
'b-tooltip': VBTooltip,
},
props: ['userId','tokenAuth'],
data() {



return {
  loading: true,
  EQCoins: 0,
}
},
computed: {

},
watch: {



},
created(){






},
mounted() {

this.getEQCoins();



},
methods: {




refreshEQCoins() {
    this.getEQCoins();

},
getEQCoins(){
    this.loading=true;




this.$https.post('/profile/getNumberEQCoinsReferidos/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
    

  if (response.data.code == 200) {
    this.loading = false;

  
    this.EQCoins= response.data.EQCoins;

    
  } else {




    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
      
      localStorage.removeItem('userData')



      
      

       

      
      

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    } else {
       this.getEQCoins();
    }
  }
}).catch(error => {
     this.getEQCoins();
})


},
}
}

</script>

<style lang="scss">







</style>
