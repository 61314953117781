<template>



    <div class="card" >



      <div class="card-body ">

        <b-row>

                  <b-col cols="1" sm="1" md="1" lg="1" xl="1">

                    
                      <b-skeleton width="10px" v-if="loading" height="10px" type="button"></b-skeleton>

                    

                    <feather-icon
                     v-else
                    icon="RefreshCcwIcon"
                    size="18"
                    @click="refresh"
                    class="reload"
                    />


             </b-col>

    

              <b-col cols="10" sm="10" md="10" lg="10" xl="10" >



            </b-col>

            <b-col cols="1" sm="1" md="1" lg="1" xl="1"></b-col>

     

            <b-col cols="8" sm="8" md="8" lg="8" xl="8" >
            

                <p style=" text-align: left;
                        font-size: 12px;
                        margin-top: 8px;
                    margin-bottom:0px; ">Cantidad de referidos
                    <feather-icon
                        icon="HelpCircleIcon"
                        size="16"
                        v-b-popover.hover.bottom.html="'Cantidad de usuarios registrados con tu link de promotor'"
                        title="Número de referidos"
                        style="margin-right: 5px;cursor:pointer;"
                        /> </p>



                        <div v-if="loading">
                          <b-skeleton width="100%" type="button" style="margin-bottom: 10px"></b-skeleton>

                        </div>

                  <p  v-else style=" text-align: left;
                        font-size: 30px;
                        margin-top: 5px;
                        margin-bottom: 20px;
                        font-weight: bold">{{numReferidos}}<small style=" font-weight: bold;
                    position: absolute;
                    top: 27px;
                    font-size: 10px;">Referidos</small></p>
                

            </b-col>

             <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding: 0;display:flex" >

                <svg style="position: absolute;right: 0;top: -25px;margin:auto; fill:#6a6a6a; width: 60px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 455 455"><g><circle cx="69.803" cy="299.013" r="34.902"/><path d="M69.803 363.914C31.252 363.914.0 395.167.0 433.718h139.606c0-38.551-31.252-69.804-69.803-69.804z"/><circle cx="385.197" cy="299.013" r="34.902"/><path d="M385.197 363.914c-38.551.0-69.803 31.252-69.803 69.803H455c0-38.55-31.252-69.803-69.803-69.803z"/><circle cx="227.5" cy="56.184" r="34.901"/><path d="M227.5 121.085c-38.551.0-69.803 31.252-69.803 69.803h139.606c0-38.551-31.252-69.803-69.803-69.803z"/><polygon points="242.5,240.773 212.5,240.773 212.5,308.896 153.504,342.958 168.504,368.939 227.5,334.877 286.496,368.939 301.496,342.958 242.5,308.896"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>


            


             </b-col>



        </b-row>


      </div>

   

    </div>



</template>

<script>
import {
BButton,BCardFooter,BLink,BFormSelect,VBTooltip, VBPopover, BRow, BCol, BCard,BSkeleton, BAvatar
} from 'bootstrap-vue'


export default {
components: {
BButton,
BCardFooter,
BRow,
BCol,
BLink,
BCard,
VBPopover,
BAvatar,
BFormSelect,
BSkeleton,
},
directives: {
'b-popover': VBPopover,
'b-tooltip': VBTooltip,
},
props: ['userId','tokenAuth'],
data() {



return {
  loading: true,
  numReferidos: 0,
}
},
computed: {

},
watch: {



},
created(){






},
mounted() {

this.getNumberReferidos();



},
methods: {




refresh() {
    this.getNumberReferidos();

},
getNumberReferidos(){
    this.loading=true;


this.$https.post('/profile/getNumberReferidosByUser/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
    

  if (response.data.code == 200) {
    this.loading = false;

  
    this.numReferidos= response.data.numReferidos;

    
  } else {




    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
      
      localStorage.removeItem('userData')



      
      

       

      
      

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    } else {
       this.getNumberReferidos();
    }
  }
}).catch(error => {
     this.getNumberReferidos();
})


},
}
}

</script>

<style lang="scss">







</style>
