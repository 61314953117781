<template>

    <b-card   class="card_opciones">
    
    
    
    <b-row @click="copyTextPromotor">
    
    
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
            <p class="comparte">Comparte tu código de promotor  <svg class="arrow_more" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.633 20.633"><g><path d="M15.621 9.844 5.971.195C5.842.064 5.674.0 5.5.0c-.171.0-.343.064-.473.195L5.014.207c-.126.127-.197.299-.197.475v4.682c0 .178.071.348.197.471l4.481 4.482-4.481 4.479c-.126.126-.197.294-.197.475v4.68c0 .18.071.354.197.475l.013.01c.124.127.294.197.473.197s.348-.07.474-.197l9.647-9.646C15.881 10.531 15.881 10.104 15.621 9.844z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg></p>
           
        
      
    
    </b-col>

    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <p class="comparte2">Es útil para usuarios que ya están registrados pero no tienen promotor</p>
    

        </b-col>
    
 
    
    </b-row>
    </b-card>
    
    </template>
    
    <script>
    import {
    BButton, BRow, BCol,BCard
    } from 'bootstrap-vue'
    
    
    export default {
    components: {
    BButton,
    BRow,
    BCol,
    BCard,
    
    },
    props: ['promoCode', 'displayName', 'isMobile'],
    data() {
      
 
      
    return {
     
    }
    },
    computed: {
    
    },
    watch: {
    
    
    
    },
    created(){
    
    
    
    
    
    
    },
    mounted() {
    
    
    
    },
    methods: {
    
        copyTextPromotor() {
    
    
     
        if(this.isMobile){
    
          if (navigator.share) {
                navigator.share({
                  title: 'EQCoop - Código promotor de' + this.displayName,
                  text:  this.promoCode,
                })
                  .then(() => console.log('Successful share'))
                  .catch((error) => console.log('Error sharing', error));
              }
    
        }else{
    
          
            // Copy the text inside the text field
            navigator.clipboard.writeText(this.promoCode);
    
              this.$toast.success('Código de promotor copiado!', {
                position: 'top-right',
                timeout: 1500,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
    
        }
    
    
     
    
    
    
    
            },
    
    
    
    
    }
    }
    
    </script>
    
    <style lang="scss">
    
    
    
    
    
    
    
    </style>
    