<template>



    <div class="card" >



      <div class="card-body ">


                    <b-row  v-if="loading">

                      <b-col cols="10" sm="10" md="10" lg="10" xl="10">

                        <b-skeleton width="50%"  height="10px"  style="margin-bottom:10px"></b-skeleton>


                            <b-skeleton width="100%"  height="30px"  style="margin-bottom:20px"></b-skeleton>

                            <b-skeleton width="30%"  height="10px"  ></b-skeleton>

                      </b-col>


                      <b-col cols="2" sm="2" md="2" lg="2" xl="2">

                        <svg style="position: absolute;right: 0;top: -5px;margin:auto; fill:#6a6a6a; width: 60px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 612 612" ><g><path d="M251.733 288.168c79.576.0 144.084-64.509 144.084-144.084S331.31.0 251.733.0c-79.575.0-144.084 64.509-144.084 144.084s64.509 144.084 144.084 144.084zm67.338-86.79c-6.555 21.671-34.632 37.963-68.283 37.963s-61.728-16.292-68.282-37.963H319.071zm284.639 224.9c-3.09 85.35-37.799 158.706-118.848 185.722-81.049-27.016-115.758-100.372-118.848-185.722-.242-6.655 5.258-12.049 11.91-11.765 37.873 1.615 70.926-10.577 99.877-34.521 4.074-3.37 10.045-3.37 14.121.0 28.949 23.943 62.004 36.136 99.875 34.521C598.452 414.229 603.95 419.623 603.71 426.278zM341.194 427.167c1.414 39.206 8.896 73.968 21.902 103.688-30.627 3.456-67.427 5.733-111.363 5.733-138.927.0-206.913-22.701-231.838-33.792-6.431-2.86-11.613-11.042-11.613-18.082V437.22c0-71.171 54.188-130.214 123.333-138.123 2.101-.241 5.216.659 6.929 1.899 31.881 23.08 70.912 36.854 113.19 36.854s81.31-13.774 113.19-36.854c1.713-1.24 4.828-2.14 6.93-1.899 39.904 4.564 74.748 26.218 97.148 57.348-2.482 1.222-4.875 2.624-7.023 4.403-23.57 19.485-48.898 28.966-77.418 28.966-1.844.0-3.697-.039-5.588-.126l-1.543-.029c-9.811.0-19.33 4.047-26.133 11.102C344.522 407.788 340.835 417.424 341.194 427.167z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                        </b-col>

                      

                    </b-row>

                    
                  
                      <b-row v-else-if="code200">

                        <b-col cols="10" sm="10" md="10" lg="10" xl="10">

                          <p  style=" text-align: left; font-size: 15px; margin-bottom:0px; ">Tu promotor es: </p>
                        <p  style="  text-transform: capitalize;white-space: nowrap;text-overflow: ellipsis;overflow: hidden; text-align: left; font-size: 16px; margin-bottom:0px;margin-top:12px; font-weight: bold;">{{namePromotor}} </p>
                        <p  style=" text-align: left; font-size: 10px; margin-bottom:20px; ">{{contacto}} </p>

                          </b-col>


                          <b-col cols="2" sm="2" md="2" lg="2" xl="2">

                            <svg style="position: absolute;right: 0;top: -5px;margin:auto; fill:#6a6a6a; width: 60px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 612 612" ><g><path d="M251.733 288.168c79.576.0 144.084-64.509 144.084-144.084S331.31.0 251.733.0c-79.575.0-144.084 64.509-144.084 144.084s64.509 144.084 144.084 144.084zm67.338-86.79c-6.555 21.671-34.632 37.963-68.283 37.963s-61.728-16.292-68.282-37.963H319.071zm284.639 224.9c-3.09 85.35-37.799 158.706-118.848 185.722-81.049-27.016-115.758-100.372-118.848-185.722-.242-6.655 5.258-12.049 11.91-11.765 37.873 1.615 70.926-10.577 99.877-34.521 4.074-3.37 10.045-3.37 14.121.0 28.949 23.943 62.004 36.136 99.875 34.521C598.452 414.229 603.95 419.623 603.71 426.278zM341.194 427.167c1.414 39.206 8.896 73.968 21.902 103.688-30.627 3.456-67.427 5.733-111.363 5.733-138.927.0-206.913-22.701-231.838-33.792-6.431-2.86-11.613-11.042-11.613-18.082V437.22c0-71.171 54.188-130.214 123.333-138.123 2.101-.241 5.216.659 6.929 1.899 31.881 23.08 70.912 36.854 113.19 36.854s81.31-13.774 113.19-36.854c1.713-1.24 4.828-2.14 6.93-1.899 39.904 4.564 74.748 26.218 97.148 57.348-2.482 1.222-4.875 2.624-7.023 4.403-23.57 19.485-48.898 28.966-77.418 28.966-1.844.0-3.697-.039-5.588-.126l-1.543-.029c-9.811.0-19.33 4.047-26.133 11.102C344.522 407.788 340.835 417.424 341.194 427.167z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                            </b-col>

                    
                      </b-row>

                      <b-row  v-else>

                        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <p  style=" text-align: center; font-size: 12px; margin-top: 0px;margin-bottom:0px; ">No tienes un promotor</p>
                            <p  style=" text-align: center; font-size: 16px; margin-top: 0px;margin-bottom:15px;font-weight: bold; ">¿Deseas apoyar a uno? </p>


                            </b-col>


                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center">

                              <b-button  class="animacion_button"  variant="primary" @click="showModalPromotor" >

                                            Apoyar a un promotor

                                            </b-button>


                              </b-col>

                      </b-row>

                    


    


      </div>

      <b-modal
      ref="modalPromotor"
      centered
      size="sm"
      title="Registro de promotor"
      hide-footer

    >
      <div class="d-block text-center">

        <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmitAddPromotor"
                           
                            
                        >
                   
                      <b-row   >

                        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <p class="textLabel" >Ingrese el código de promotor (*):</p>

                            <b-form-input
                            autofocus
                      
                      v-model="codigoPromotor"
                          placeholder="Ingrese un código de promotor"
                          required
                          type="text"
                          :readonly="isDisabled"
                      />
                    
                        </b-col>

                        <b-col
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            style="margin-top:10px"
                            >

                        

                            <p
                                class="textLabel"
                                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
                            >
                                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                                <PincodeInput
                                    v-model="pin"
                                    placeholder="-"
                                    :length="6"
                                    :autofocus="false"
                                    :secure="true"
                                    :disabled="isDisabled"
                                
                                    required
                                />

                                </b-col>

                                <b-col
                                            sm="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            >

                                            <b-button
                                            block
                                                id="btn_promotor"
                                                ref="btn_promotor"
                                                type="submit"
                                                variant="primary"
                                                :disabled="isDisabled"
                                            >

                                            Registrar promotor

                                            </b-button>

                                            </b-col>

                      </b-row>

                      </b-form>
       

        </div>

        </b-modal>

   

    </div>



</template>

<script>
import {
     BButton,BCardFooter,BLink,BFormInput,VBTooltip, VBPopover, BRow, BCol, BCard,BSkeleton, BAvatar, BForm
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'

export default {
components: {
BButton,
BForm,
BCardFooter,
BRow,
BCol,
BLink,
PincodeInput,
BCard,
VBPopover,
BAvatar,
BFormInput,
BSkeleton,
},
directives: {
'b-popover': VBPopover,
'b-tooltip': VBTooltip,
},
props: ['userId','tokenAuth'],
directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
data() {



return {
  loading: true,
  code200: false,
  contacto:"",
  isDisabled:false,
  namePromotor:"",
  pin:"",
  codigoPromotor:""
}
},
computed: {

},
watch: {



},
created(){






},
mounted() {

this.getPromotor();



},
methods: {

  showModalPromotor(){
    this.$refs.modalPromotor.show()
  
  },

  onSubmitAddPromotor(event) {
      event.preventDefault()

   



      const userId_json = {
        userId: this.userId, codigoPromotor: this.codigoPromotor.trim(), pin: this.pin
      }



      const user_string = JSON.stringify(userId_json)

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
    
      this.isDisabled = true

      document.getElementById('btn_promotor').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Procesando'

      this.$https.post('/profile/registerPromoter/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
      this.isDisabled = false

    
  
        document.getElementById('btn_promotor').innerHTML = 'Apoyar al promotor';
       

        if (response.data.code == 200) {

          this.$refs.modalPromotor.hide()
  

          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

          this.getPromotor();



        } else {
          

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          } 
        }
      }).catch(error => {

        this.isDisabled = false
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      

    
    },


getPromotor(){
    this.loading=true;



this.$https.post('/profile/getPromotor/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
    

  if (response.data.code == 200) {
    this.loading = false;
    this.code200=true;
  
    this.namePromotor= response.data.namePromotor.toLowerCase();
    this.contacto= response.data.contacto;
    
  } else {




    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
      
      localStorage.removeItem('userData')



      
      

       

      
      

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    } else {

        if(response.data.code=== 405){
            this.loading = false;
            this.code200=false;

        }else{
            this.getPromotor();
        }
      
    }
  }
}).catch(error => {
     this.getPromotor();
})


},
}
}

</script>

<style lang="scss">







</style>
