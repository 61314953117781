<template>



    <div class="card" >



      <div class="card-body ">

        <b-row>


            <b-col cols="8" sm="8" md="8" lg="8" xl="8" >
            

                <p style="margin-bottom:5px; margin-top:0px; font-size:14px; text-align: left;">Tu rango actual es:</p>
                <b-skeleton v-if="loading" width="100%" height="20px" style="margin-bottom: 10px;" ></b-skeleton>
                <p style="font-size:18px;font-weight: bold; margin-bottom: 10px;text-align: left !important"  v-else>Promotor {{nivel}}</p>

      


            </b-col>

             <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="padding: 0;display:flex" >

                           <b-skeleton v-if="loading" width="55px" type="avatar"   height="55px" style="position: absolute;right: 0;top: -8px;margin:auto;"></b-skeleton>

                    <b-avatar
                    v-else
                        size="55"
                        variant="light-primary"
                        style="position: absolute;right: 0;top: -8px;margin:auto"
                        >

                        <span class="d-flex align-items-center">
                            <img alt="Rango de promotor"  :src="icon" style="width: 40px;">
    
                          
                        </span>

                        </b-avatar>

            


             </b-col>

             <b-col  cols="12" sm="12" md="12" lg="12" xl="12" align="center" >

                <b-button
                                            
                                            :to="{ name: 'rangos'}"
                                                variant="primary"
                                         
                                            >

                                            ¿Cómo funcionan los rangos?

                                            </b-button>

         

     </b-col>




        </b-row>


      </div>

   

    </div>



</template>

<script>
import {
BButton,BCardFooter,BLink,BFormSelect,VBTooltip, VBPopover, BRow, BCol, BCard,BSkeleton, BAvatar
} from 'bootstrap-vue'


export default {
components: {
BButton,
BCardFooter,
BRow,
BCol,
BLink,
BCard,
VBPopover,
BAvatar,
BFormSelect,
BSkeleton,
},
directives: {
'b-popover': VBPopover,
'b-tooltip': VBTooltip,
},
props: ['userId','tokenAuth'],
data() {



return {
  loading: true,
  nivel:"",
    icon:"",
}
},
computed: {

},
watch: {



},
created(){






},
mounted() {

this.getRango();



},
methods: {


    getRango(){
    this.loading=true;


this.$https.post('/inversion/getNivel/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
    

    if (response.data.code == 200) {
    this.loading = false;

    this.nivel= response.data.nivel;
    this.icon=response.data.icon; 


    
    } else {




    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
        })
        
        localStorage.removeItem('userData')

        

        
        

        

        
        

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
    } else {
        this.getRango();
    }
    }
}).catch(error => {
    this.getRango();
})



},
}
}

</script>

<style lang="scss">







</style>
