<template>

    
  
    <b-row :key="componentKeyIndexReferidos">
      

      <b-col  lg="12" md="12" sm="12" cols="12" xl="12" v-if="!isMobile" style="padding:0px">

        <b-row>

          <b-col  lg="3" md="6" sm="12" cols="12" xl="3">
            <card-num-referidos   :user-id="userId" :token-auth="tokenAuth" />
    
          </b-col>

          <b-col  lg="3" md="6" sm="12" cols="12" xl="3">

            <e-q-coins-ganados  :user-id="userId" :token-auth="tokenAuth" />
</b-col>

<b-col  lg="3" md="6" sm="12" cols="12" xl="3">
  <promotor  :user-id="userId" :token-auth="tokenAuth" />
</b-col>

<b-col  lg="3" md="6" sm="12" cols="12" xl="3">
  <rango   :user-id="userId" :token-auth="tokenAuth" />
</b-col>


 

        </b-row>

   


        </b-col>

      
      <b-col  lg="12" md="12" sm="12" cols="12" xl="12" v-else>
        <carousel :autoplay="false" :nav="false" :dots="false" :stagePadding="10"  :items="4" :margin="5" :responsive="{0:{items:1,nav:false},700:{items:2,nav:false},770:{items:2,nav:false},1200:{items:3,nav:false},1300:{items:4,nav:false}}">

        
          <card-num-referidos   :user-id="userId" :token-auth="tokenAuth" />
    
           <e-q-coins-ganados  :user-id="userId" :token-auth="tokenAuth" />
          <promotor  :user-id="userId" :token-auth="tokenAuth" />
          <rango   :user-id="userId" :token-auth="tokenAuth" />
        </carousel>

        </b-col>

        
        <b-col cols="12" sm="12" md="12" lg="6" xl="6">

          <b-row>

            <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="padding: 0px;" v-if="!isMobile">

        
              <codigo-promotor :promo-code="promoCode" :display-name="displayName" :is-mobile="isMobile" />
                    

              <link-referidos :promo-code="promoCode" :display-name="displayName" :is-mobile="isMobile" />
                                


              </b-col>

              <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="padding: 0px;" v-else>

                <carousel :autoplay="false" :nav="false" :dots="false" :stagePadding="10"  :items="4" :margin="5" :responsive="{0:{items:1,nav:false},700:{items:2,nav:false},770:{items:2,nav:false},1200:{items:3,nav:false},1300:{items:4,nav:false}}">

                  <codigo-promotor :promo-code="promoCode" :display-name="displayName" :is-mobile="isMobile" />
                        

                  <link-referidos :promo-code="promoCode" :display-name="displayName" :is-mobile="isMobile" />
                     
                  
                  </carousel>


                  </b-col>


              <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-bottom:10px">

                  <app-collapse
                      accordion
                      type="margin"
                      class="address_card"
              
                    >

                      <app-collapse-item title="QR de link de promotor" :isVisible="isVisible" icon="InfoIcon">

                        <b-row>

                          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 10px;">

                          <b-alert
                              variant="primary"
                              show
                              style="text-align: center;"

                              >
                              <div class="alert-body">
                                  <span> Comparte tu código QR con tus conocidos para que se registren y recibe a cambio EQCoins.</span>
                              </div>
                              </b-alert>


                          </b-col>

                       

                          <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                              <b-skeleton  height="130px" width="50%" animation="fade"></b-skeleton>


                              </b-col>

                          <b-col  align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px"> 
                            <vue-qr  style="width: 200px !important;" :callback="getDataURL" :margin="margin"  logoBackgroundColor="#fff" backgroundColor="#f0f2f5"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius"  :text="link" :correctLevel="correctLevel" :size="size"></vue-qr>
                                

                  
                              </b-col >

                              <b-col v-if="hideQR" cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                              <b-skeleton height="30px"></b-skeleton>


                              </b-col>
                              <b-col v-else cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                <b-link @click="descargar()" class="icon_dashboard"> 


                                        <b-avatar
                                        size="40"
                                        variant="light-primary"
                                        style="cursor:pointer;">

                                        <span class="d-flex align-items-center">
                                          <feather-icon
                                          icon="DownloadIcon"
                                          size="21"
                                        />

                                        </span>

                                        </b-avatar>

                                  

                                        </b-link>

                              

                                </b-col>






                              <b-col v-if="hideQR" cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                  <b-skeleton height="30px"></b-skeleton>



                                </b-col>

                                <b-col v-else cols="6" sm="6" md="6" lg="6" xl="6" align="center" >

                                  <b-link @click="shared()" class="icon_dashboard"> 


                                        <b-avatar
                                        size="40"
                                        variant="light-primary"
                                        style="cursor:pointer;">

                                        <span class="d-flex align-items-center">
                                          <feather-icon
                                          icon="Share2Icon"
                                          size="21"
                                        />

                                        </span>

                                        </b-avatar>

                                    

                                        </b-link>


                                  </b-col>

      

                              
                        


                                  


                    


                        </b-row>


                  
                      </app-collapse-item>



                    </app-collapse>

               

            </b-col>

          </b-row>


   
              




      </b-col>

      <b-col cols="12" sm="12" md="12" lg="6" xl="6">
        <b-card>

        <b-tabs
            class="row"
          content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0"
          pills
          nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          nav-class="nav-left"
         align="center"
        >



          <b-tab active>

            <template #title>
              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">   
                  <feather-icon
                        icon="UserXIcon"
                        size="21"
                        class="color_icon"
                        /> Referidos no verificados</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">    
                <feather-icon
                        icon="UserXIcon"
                        size="21"
                        class="color_icon"
                        /> Referidos no verificados</span>
            </template>

            <listado-referidos
              :key="componentKeyListadoReferidos"
              :user-id="userId"
              :token-auth="tokenAuth"
              :columnas="columnas"
            />
      

          </b-tab>

          <b-tab @click="listVerificados">

            <template #title>


              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">
                 <feather-icon
                        icon="UserCheckIcon"
                        size="21"
                        class="color_icon"
                        />
                        Referidos verificados</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">
                 <feather-icon
                        icon="UserCheckIcon"
                        size="21"
                        class="color_icon"
                        />
                        Referidos verificados</span>
            </template>

            <listado-verificados
              v-if="ifVerificados"
              :key="componentKeyListadoVerificados"
              :user-id="userId"
              :token-auth="tokenAuth"
              :columnas="columnas"
            />

          </b-tab>

         

        </b-tabs>


        </b-card>




        </b-col>

    


     </b-row>


</template>

<script>
import { BRow, BCol,BAlert,BSkeleton,BCard,BAvatar,BLink,BTabs, BTab, VBTooltip,VBPopover,BDropdown,BDropdownItem,BInputGroup,BButton, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, } from 'bootstrap-vue'
import ListadoReferidos from './ListadoReferidos.vue'
import ListadoVerificados from './ListadoVerificados.vue'
import AppCollapse from '@core/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/app-collapse/AppCollapseItem.vue'
import VueQr from 'vue-qr'
import CardNumReferidos from './CardNumReferidos.vue'
import EQCoinsGanados from './EQCoinsGanados.vue'
import Rango from './Rango.vue'
import Promotor from './Promotor.vue'
import carousel from 'vue-owl-carousel'
import LinkReferidos from './LinkReferidos.vue'
import CodigoPromotor from './CodigoPromotor.vue'
export default {
  components: {
    BRow,
    Rango,
    BTabs,
    BTab,
    VueQr,
    carousel,
    BLink,
    ListadoVerificados,
    LinkReferidos,
    CodigoPromotor,
    BSkeleton,
    BCard,
    BAlert,
    ListadoReferidos,
    CardNumReferidos,
    EQCoinsGanados,
    Promotor,
    BCol,
    BButton,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    BDropdown,BDropdownItem,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
  },
  directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);

    let link="";
       

    

    
    
       let promoCode= datos_json.promoCode;
       let displayName= datos_json.displayName;
       link=window.location.host+"/invitacion/"+promoCode;
   
          const mq = window.matchMedia( "(min-width: 990px)" );
        let columnas=4;

        if (!mq.matches) {

          columnas =3;
          }

          let isVisible=true;

          if(this.$isMobile){
            isVisible=false;
          }


    return {
      isMobile:this.$isMobile,
      userId: datos_json.userId,
      tokenAuth: this.$tokenGlobal,
      componentKeyIndexReferidos:0,
      componentKeyListadoReferidos:0,
      componentKeyListadoVerificados:0,
      columnas:columnas,
      displayName:displayName,
      isVisible:isVisible,
      link:link,
      size:800,
      logoScale:0.3,
            logoMargin: 1,
            logoCornerRadius:100,
            margin:50,
            correctLevel:3,
            dataUrl:"",
            hideQR:true,
            promoCode:promoCode,
            ifVerificados:false,
    }
  },
  created() {

  },
  activated() {

this.$eventBus.$emit('reiniciarMenu1')
 this.$eventBus.$emit('reiniciarMenu2')


},
  mounted() {




  this.$eventBus.$on('reiniciarReferidos', () => {
    this.componentKeyIndexReferidos += 1
  })

  this.$eventBus.$on('reiniciarListadoReferidos', () => {
    this.componentKeyListadoReferidos += 1
  })


  this.$eventBus.$on('reiniciarListadoVerificados', () => {
      this.componentKeyListadoVerificados += 1
    });

    document.title = 'EQCoop - Referidos'

  },
  methods: {


    listVerificados(){
      this.ifVerificados=true;

    },
    
    getDataURL(dataUrl,id){

this.dataUrl= dataUrl;
this.hideQR=false

},
descargar(){
    
    var link = document.createElement("a");
    link.download = "Link de promotor de " +  this.displayName;
    link.href =this.dataUrl;
    link.click();
     
     

  },
  shared(){
    
    
    fetch(this.dataUrl).then(function (response) {
      return response.blob()
    }).then(function (blob) {
      let file = new File([blob], "Link de promotor.jpg", {type: 'image/jpeg'});
      let filesArray = [file];
      if (navigator.canShare && navigator.canShare({files: filesArray})) {
        navigator.share({
          files: filesArray
        }).then(() => console.log('Successful share'))
          .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          }));
      }else{

          this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

      }
    });


  

  },

  },
}
</script>

<style lang="scss" >



</style>
